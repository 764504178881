import {
  CheckBox,
  TextInput,
  LabelPosition,
  LabelAlign,
  SelectorVariant,
  Button,
  ButtonVariant,
  TextInputWidth,
  TextInputSize,
  LabelSize,
  Link,
  IconName,
  ButtonIconAlign,
} from "@eightfold.ai/octuple";
import React, { useEffect, useState, useContext } from "react";
import { secondaryButtonStyle } from "../../commons/commonStyles";
import { useNavigate } from "react-router";
import { validateFields, validationOptions } from '../../ValidationSchema';
import { globalContext } from "../../store";
import { updateDemographicData } from '../../commons/commonFunctions';

const Account = (props: any) => {
  const navigate = useNavigate();
  const { globalState, dispatch } = useContext(globalContext);
  const [privacyPolicyChecked, setPrivacyPolicyCheck] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setfirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [errors, setErrors] = useState<any>({});

  const [disableEmail, setDisableEmail] = useState(false);
  const [disableFirstName, setDisableFirstName] = useState(false);
  const [disableLastName, setDisableLastName] = useState(false);
  const [disablePrivacyPolicyChecked, setDisablePrivacyPolicyChecked] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (props) {
      setfirstName(props?.firstName);
      setLastName(props?.lastName);
      setEmail(props?.email);
      setPrivacyPolicyCheck(props?.isPrivacyPolicyAccepted)
      setDisableEmail(props?.email !== "Select" && props?.email !== "" && props?.email !== undefined);
      setDisableFirstName(props?.firstName !== "Select" && props?.firstName !== "" && props?.firstName !== undefined);
      setDisableLastName(props?.lastName !== "Select" && props?.lastName !== "" && props?.lastName !== undefined);
      setDisablePrivacyPolicyChecked(props?.isPrivacyPolicyAccepted !== "Select" && props?.isPrivacyPolicyAccepted !== "" && props?.isPrivacyPolicyAccepted !== undefined && props?.isPrivacyPolicyAccepted !== false)
    }
  }, [props]);

  const saveAccountData = () => {
    const firstNameMsg = validateFields.validate({ textField: firstName ? firstName : '' }, validationOptions)?.error?.details[0]?.message;
    const emailMsg = validateFields.validate({ email: email ? email : '' }, validationOptions)?.error?.details[0]?.message;
    const lastNameMsg = validateFields.validate({ textField: lastName ? lastName : '' }, validationOptions)?.error?.details[0]?.message;
    const privacyPolicyCheckedMsg = validateFields.validate({ privacyPolicy: privacyPolicyChecked ? privacyPolicyChecked : '' }, validationOptions)?.error?.details[0]?.message;

    if (firstNameMsg || emailMsg || lastNameMsg || privacyPolicyCheckedMsg) {
      setErrors({
        firstNameMsg: firstNameMsg,
        lastNameMsg: lastNameMsg,
        emailMsg: emailMsg,
        privacyPolicyCheckedMsg: privacyPolicyCheckedMsg
      });
      return;
    }

    const payload = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      applications: {
        leaderPortal: {
          isPrivacyPolicyAccepted: privacyPolicyChecked,
        },
      },
    }

    const newContextData = { ...globalState?.leaderData, ...payload }
    const redirectUrl = '/register-role';
    updateDemographicData({payload, leaderProfileId : props.leaderProfileId, redirectUrl, setErrorMsg, newContextData, dispatch, navigate, setShowLoader});
  }

  const policyLabel = () => {
    return (
      <>I have read and accept the&nbsp;</>
    );
  };

  return (
    <div className="accountWrapper">
      <h1>Account</h1>
      <h2>Start by creating an account</h2>

      <div className="fieldWrap">
        <TextInput
          htmlType="text"
          id="firstName"
          clearable
          labelProps={{
            text: "First Name*",
            size: LabelSize.Medium,
          }}
          maxlength={100}
          minlength={0}
          name="firstName"
          value={firstName}
          onChange={(e) => {
            setfirstName(e.target.value);
          }}
          inputWidth={TextInputWidth.fill}
          classNames="inputTextField"
          size={TextInputSize.Large}
          status={errors?.firstNameMsg ? 'error' : 'validating'}
          disabled={disableFirstName ?? false}
        />
        {errors?.firstNameMsg && <span className="errorMessage">{errors?.firstNameMsg}</span>}
      </div>

      <div className="fieldWrap">
        <TextInput
          htmlType="text"
          id="lastName"
          clearable
          labelProps={{
            text: "Last Name*",
            size: LabelSize.Medium,
          }}
          maxlength={100}
          minlength={0}
          name="lastName"
          value={lastName}
          onChange={(e) => {
            setLastName(e.target.value);
          }}
          inputWidth={TextInputWidth.fill}
          classNames="inputTextField"
          size={TextInputSize.Large}
          status={errors?.lastNameMsg ? 'error' : 'validating'}
          disabled={disableLastName ?? false}
        />
        {errors?.lastNameMsg && <span className="errorMessage">{errors?.lastNameMsg}</span>}
      </div>

      <div className="fieldWrap">
        <TextInput
          htmlType="email"
          id="email"
          clearable
          labelProps={{
            text: "Email*",
            size: LabelSize.Medium,
          }}
          maxlength={100}
          minlength={0}
          name="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          inputWidth={TextInputWidth.fill}
          classNames="inputTextField"
          size={TextInputSize.Large}
          status={errors?.emailMsg ? 'error' : 'validating'}
          disabled={disableEmail ?? false}

        />
        {errors?.emailMsg && <span className="errorMessage">{errors?.emailMsg}</span>}
      </div>

      <div className="filedWrap-Link">
        <div style={{ display: 'flex' }}>
          <CheckBox
            ariaLabel="Label"
            id="privacyPolicy"
            label={policyLabel()}
            labelAlign={LabelAlign.Center}
            labelPosition={LabelPosition.End}
            name="myCheckBoxName"
            checked={privacyPolicyChecked}
            onChange={(e) => { setPrivacyPolicyCheck(e.target.checked) }}
            variant={SelectorVariant.Default}
            classNames="formCheckbox"
            disabled={disablePrivacyPolicyChecked ?? false}
          />
          <Link
            classNames="privacyPolicy"
            href="javascript:void(0)"
            onClick={() => { window.open('https://www.heidrick.com/en/privacy/privacy-notice-english', "_blank", "noopener,noreferrer") }}
            target="_self"
            underline
            variant="primary"
          >
            Privacy Policy
          </Link>
        </div>
        {errors?.privacyPolicyCheckedMsg && <span className="errorMessage">{errors?.privacyPolicyCheckedMsg}</span>}
      </div>

      <div className="fieldWrap-Btn">
        <Button
          text="Continue"
          variant={ButtonVariant.Secondary}
          classNames="secondaryButton"
          style={secondaryButtonStyle}
          onClick={() => { saveAccountData() }}
          iconProps={(showLoader) ? { path: IconName.mdiLoading, spin: true } : undefined}
          alignIcon={ButtonIconAlign.Right}
          disabled={showLoader}
        />
      </div>
      {errorMsg && <span className="apiErrorMessage">An error occurred</span>}

    </div>
  );
};

export default Account;
