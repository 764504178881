import React, { useContext, useState } from "react";
import { Button, ButtonVariant, IconName, ButtonIconAlign } from "@eightfold.ai/octuple";
import Header from "../commons/Header";
import { secondaryButtonStyle } from "../commons/commonStyles";
import Footer from "../commons/Footer";
import Api from "../Api";
import { globalContext } from "../store";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

const WelcomePage = () => {

  const navigate = useNavigate();
  const { globalState, dispatch } = useContext(globalContext);
  const [showLoader, setShowLoader] = useState(false);
  const title = globalState?.clientData?.name;
  let loginToken: any = JSON.parse(
    sessionStorage.getItem("loginToken") ?? "false"
  );

  let clientId: any = JSON.parse(
    sessionStorage.getItem("clientId") ?? "false"
  );

  const payload = {
    email: loginToken?.idToken?.claims?.email,
    clientId: clientId,
  };

  const areFieldsPresent = (obj: any, fields: any) => {
    return fields.every((field: any) => field.split('.').reduce((o: any, k: any) => (o || {})[k], obj));
  }

  let accountNotCompleted = true;
  let roleNotCompleted = true;
  let companyNotCompleted = true;
  let personalNotCompleted = true;
  
  const accountFields = ['firstName', 'lastName', 'email','applications.leaderPortal.isPrivacyPolicyAccepted'];
  const roleFields = [
    'demographicSurveyData.jobTitle',
    'demographicSurveyData.jobLevel',
    'demographicSurveyData.hireDate',
    'demographicSurveyData.jobFunction',
    'demographicSurveyData.jobSubFunction',
    'demographicSurveyData.yearsAtJobLevel',
    'demographicSurveyData.yearsInLeadershipRole',
    'demographicSurveyData.directReports',    
  ];
  const companyFields = [
    'demographicSurveyData.industry',
    'demographicSurveyData.industrySector',
    'demographicSurveyData.orgType',
    'demographicSurveyData.orgStructure',
    'demographicSurveyData.orgSize',
    'demographicSurveyData.orgCycle',
    'demographicSurveyData.orgRevenue',
    'demographicSurveyData.workingEnvironment'
  ];
  let personalFields = [
    'demographicSurveyData.gender',
    'demographicSurveyData.countryOfResidence',
    'demographicSurveyData.ethnicity',
    'demographicSurveyData.age',
    'demographicSurveyData.education',    
  ];
  
  const redirectionPaths =(res:any) =>{
        
    if (res?.demographicSurveyData?.countryOfResidence === 'United States of America (the)' || res?.demographicSurveyData?.countryOfResidence === 'United Kingdom of Great Britain and Northern Ireland (the)') {
      personalFields.push('demographicSurveyData.lgbtq');
      personalFields.push('demographicSurveyData.diversityNonSpecific');
    }

    if (res?.demographicSurveyData?.countryOfResidence === 'United States of America (the)') {
      personalFields.push('demographicSurveyData.veteranStatus');
    }

    accountNotCompleted = !areFieldsPresent(res, accountFields);
    roleNotCompleted = !areFieldsPresent(res, roleFields);
    companyNotCompleted = !areFieldsPresent(res, companyFields);
    personalNotCompleted = !areFieldsPresent(res, personalFields);

    const navigationPaths = [
      { condition: res.isProfileCompleted, path: "/dashboard" },
      { condition: accountNotCompleted, path: "/register-account" },
      { condition: roleNotCompleted, path: "/register-role" },
      { condition: companyNotCompleted, path: "/register-company" },
      { condition: personalNotCompleted, path: "/register-personal" }
    ];

    for (let row of navigationPaths ) {
      if (row.condition) {
        navigate(row?.path);
        break;
      }
    } 
  }

  const onClickContinue = () => {
    setShowLoader(true);
    Api.getLeaderProfile(payload)
      .then((response: any) => {
        if (response?.success) {
          const res = response?.response?.data?.data?.[0];
          dispatch({
            type: "SET_LEADER_DATA",
            payload: res,
          });
          redirectionPaths(res);
           
        } else {
          setShowLoader(false);
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  return (
    <div className="mainWrapper">
      <div className="imgWrapper" style={{ background: 'url(./../assets/img/onboarding-image.png) no-repeat' }}>
        &nbsp;
      </div>
      <div className="rightWrapper">
        <Header title={title} />
        <div className="contentWrapper">
          <h1 className="titleStyle">Welcome to the Leader Portal</h1>
          <div className="middleContent">
            <p className="welcomeContent">
              This platform is designed to assist leaders like you in your professional journey. Through our comprehensive assessments, we aim to provide valuable insights and help you understand various aspects of your leadership abilities.
            </p>

            <p className="welcomeContent">
              If you need technical assistance, our support team is here to help <Link to={'mailto:support@heidrick.com'}>(support@heidrick.com)</Link>.
            </p>

            <p className="welcomeContent">
              We recommend that you complete each assessment section when you can be free from interruption. You may be assigned multiple assessment sections as part of this experience. Please ensure that you complete all assigned sections. No advanced preparation is required
            </p>

            <Button
              text="Continue"
              variant={ButtonVariant.Secondary}
              classNames="secondaryButton"
              style={secondaryButtonStyle}
              onClick={() => {
                onClickContinue();
              }}
              iconProps={(showLoader) ? { path: IconName.mdiLoading, spin: true } : undefined}
              alignIcon={ButtonIconAlign.Right}
              disabled={showLoader}
            />
          </div>
        </div>
        <Footer />
      </div>
    </div >
  );
};

export default WelcomePage;
