import React from "react";
import { footerValues } from "./commonFunctions";

const Footer = () => {

  return (
    <div className="footerWrapper">
      <div className="footerTitle">© Heidrick & Struggles</div>
      <div className="footerLinks">
        {footerValues()?.map((item, index) => {
          const lengthCheck = index + 1 === footerValues()?.length;

          return (
            <>
              <div
                className={lengthCheck ? "footerPointer" : "footerSubTitle"}
                onClick={item.onClick}
              >
                {item.url}
              </div>
              {lengthCheck ? <></> : <div className="footerDivider"></div>}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default Footer;
