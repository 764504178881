import Joi from 'joi';

export const validationOptions = {
  errors: {
    wrap: {
      label: '',
    },
  },
  abortEarly: false
};

let validationFields = {
  email: Joi.string().email({ tlds: { allow: false } }).required().messages({
    'string.empty': 'This field cannot be blank',
    'string.email': 'Email must be a valid email',
  }),
  privacyPolicy: Joi.boolean().valid(true).required().messages({
    'string.empty': 'Please confirm that you have read the privacy policy.',
    'any.only': 'Please confirm that you have read the privacy policy.'
  }),
  termsOfUse: Joi.boolean().valid(true).required().messages({
    'string.empty': 'Please confirm that you have read the terms of use.',
    'any.only': 'Please confirm that you have read the terms of use.'
  }),
  textField: Joi.string().trim().pattern(new RegExp('^([^<]|a z|A Z|1 9|(.\\.))*$')).required().messages({
    'string.empty': 'This field cannot be blank',
    'string.pattern.base': 'Text should not contain any HTML Tags'
  }),
  dropDown: Joi.string().required().messages({
    'string.empty': 'This field cannot be blank'
  }),
};
export const validateFields = Joi.object(validationFields).fork(Object.keys(validationFields), schema => schema.optional());