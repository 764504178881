import React from "react";
import { footerValues } from "./commonFunctions";
const AuthFooter = () => {

  return (
    <div className="authFooter">
      <div className="footerLeftSide">
        <p className="footerText">powered By:</p>
        <img alt="navigatorLogo" src={`./../assets/img/navigatorLogo.svg`} />

      </div>

      <div className="footerRightSide">
        <div className="authFooterLinks">
          {footerValues()?.map((item, index) => {
            const lengthCheck = index + 1 === footerValues()?.length;

            return (
              <div key={index}>
                <p
                  className={lengthCheck ? "authFooterPointer" : "authFooterSubtitle"}
                  onClick={item.onClick}
                >
                  {item.url}
                </p>
                {lengthCheck ? <div className="authFooterDividerLast"></div> : <div className="authFooterDivider"></div>}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AuthFooter;
