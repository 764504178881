import {
  Button,
  ButtonVariant,
  Select,
  Label,
  LabelSize,
  SelectSize,
  Icon,
  IconName,
  ButtonIconAlign,
  TextInput,
  TextInputWidth,
  TextInputSize,
} from "@eightfold.ai/octuple";
import React, { useState, useEffect, useContext } from "react";
import { secondaryButtonStyle } from "../../commons/commonStyles";
import { useNavigate } from "react-router";
import { globalContext } from "../../store";

import { checkSubDropDownValues, updateDemographicData } from '../../commons/commonFunctions'
import { validateFields, validationOptions } from "../../ValidationSchema";


const Company = (props: any) => {
  const navigate = useNavigate();

  const { globalState, dispatch } = useContext(globalContext);
  const [orgType, setOrgType] = useState<any>("");
  const [orgStructure, setOrgStructure] = useState<any>("");
  const [orgSize, setOrgSize] = useState<any>("");
  const [orgCycle, setOrgCycle] = useState<any>("");
  const [orgRevenue, setOrgRevenue] = useState<any>("");
  const [workingEnvironment, setWorkingEnvironment] = useState<any>("");
  const [errorMsg, setErrorMsg] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [industry, setIndustry] = useState("");
  const [industrySector, setIndustrySector] = useState("");
  const [describeIndustrySec, setDescribeIndustrySec] = useState(false);
  const [industrySecTxt, setindustrySecTxt] = useState("");
  const [errors, setErrors] = useState<any>({});

  const [industrySectOptions, setIndustrySectOptions] = useState<any>([]);
  const [disableIndustrySec, setDisableIndustrySec] = useState(true);
  const otherTxt = 'Other';
  const notApplicableList = [{ text: 'Not Applicable', value: 'Not Applicable' }];
  const otherOptionList = [{ text: `${otherTxt}`, value: `${otherTxt}` }];

  useEffect(() => {
    if (props) {
      setOrgType(props?.orgType);
      setOrgStructure(props?.orgStructure);
      setOrgSize(props?.orgSize);
      setOrgCycle(props?.orgCycle);
      setOrgRevenue(props?.orgRevenue);
      setWorkingEnvironment(props?.workingEnvironment);
      setIndustry(props?.industry);
      if (props.industrySector && props?.industrySector?.includes('~')) {
        setindustrySecTxt(props?.industrySector.replace('OTH~', ''));
        setIndustrySector(otherTxt);
      } else if (props.industrySector) {
        setIndustrySector(props.industrySector);
      }

    }
  }, [props]);

  useEffect(() => {
    if (industrySectOptions && industrySectOptions?.[0]?.text === 'Not Applicable') {
      setIndustrySector('Not Applicable');
    } else if (industrySectOptions && (industrySectOptions?.[0]?.text === otherTxt)) {
      setIndustrySector(otherTxt);
    } else if (industrySectOptions && (industrySectOptions?.[0]?.text !== 'Not Applicable') && industry && (industrySector === 'Not Applicable')) {
      setIndustrySector('');
    }
  }, [industrySectOptions]);


  const changeIndustry = (value: any) => {
    setIndustry(value?.[0]);
    const options = checkSubDropDownValues(value?.[0], 'Industry Sector', globalState?.lookUpData);
    if (value?.[0] && options && options?.length > 0) {
      setindustrySecTxt('');
      if (value?.[0] === otherTxt) {
        setIndustrySectOptions(otherOptionList);
        setDisableIndustrySec(false);
        setDescribeIndustrySec(true);
        if (props.industrySector && props.industrySector?.includes('~')) {
          setindustrySecTxt(props.industrySector.replace('OTH~', ''));
        }
        return;
      }
      setIndustrySectOptions(options);
      setDisableIndustrySec(false);

      if (value?.[0] !== otherTxt) {
        setDescribeIndustrySec(false);
      }
    } else if (value?.[0]) {
      setIndustrySectOptions(notApplicableList);
      setDisableIndustrySec(true);
    }
    if (!value?.[0]) {
      setDisableIndustrySec(true);
    }
  };

  const changeIndustrySector = (value: any) => {
    if (industrySector !== 'Not Applicable') {
      setIndustrySector(value?.[0]);
    }

    if (value?.[0] === otherTxt) {
      setDescribeIndustrySec(true);
    } else {
      setDescribeIndustrySec(false);
    }
  };


  const validateFormData = () => {
    const industryMsg = validateFields.validate({ dropDown: industry ? industry : '' }, validationOptions)?.error?.details[0]?.message;
    const industrySectorMsg = validateFields.validate({ dropDown: industrySector ? industrySector : '' }, validationOptions)?.error?.details[0]?.message;
    const orgTypeMsg = validateFields.validate({ dropDown: orgType ? orgType : '' }, validationOptions)?.error?.details[0]?.message;
    const orgStructureMsg = validateFields.validate({ dropDown: orgStructure ? orgStructure : '' }, validationOptions)?.error?.details[0]?.message;
    const orgSizeMsg = validateFields.validate({ dropDown: orgSize ? orgSize : '' }, validationOptions)?.error?.details[0]?.message;
    const orgCycleMsg = validateFields.validate({ dropDown: orgCycle ? orgCycle : '' }, validationOptions)?.error?.details[0]?.message;
    const orgRevenueMsg = validateFields.validate({ dropDown: orgRevenue ? orgRevenue : '' }, validationOptions)?.error?.details[0]?.message;
    const workingEnvironmentMsg = validateFields.validate({ dropDown: workingEnvironment ? workingEnvironment : '' }, validationOptions)?.error?.details[0]?.message;
    let industrySecDesMsg;
    if (industrySector === otherTxt) {
      industrySecDesMsg = validateFields.validate({ textField: industrySecTxt || '' }, validationOptions)?.error?.details[0]?.message;
    }

    if (industryMsg || industrySectorMsg || industrySecDesMsg || orgTypeMsg || orgStructureMsg || orgSizeMsg || orgCycleMsg || orgRevenueMsg || workingEnvironmentMsg) {
      setErrors({
        industryMsg: industryMsg,
        industrySectorMsg: industrySectorMsg,
        industrySecDesMsg: industrySecDesMsg,
        orgTypeMsg: orgTypeMsg,
        orgStructureMsg: orgStructureMsg,
        orgSizeMsg: orgSizeMsg,
        orgCycleMsg: orgCycleMsg,
        orgRevenueMsg: orgRevenueMsg,
        workingEnvironmentMsg: workingEnvironmentMsg,
      });
      return true;
    } else {
      setErrors({});
      return false;
    }
  };

  const saveCompanyData = () => {
    if (validateFormData()) return;
    const payload = {
      demographicSurveyData: {
        orgType: orgType,
        orgStructure: orgStructure,
        orgSize: orgSize,
        orgCycle: orgCycle,
        orgRevenue: orgRevenue,
        workingEnvironment: workingEnvironment,
        industry: industry,
        industrySector: (industrySector === otherTxt) ? `OTH~${industrySecTxt}` : industrySector,
      }
    };

    const updatedDataForContext: any = {
      demographicSurveyData: {
        ...globalState?.leaderData?.demographicSurveyData,
        orgType: orgType,
        orgStructure: orgStructure,
        orgSize: orgSize,
        orgCycle: orgCycle,
        orgRevenue: orgRevenue,
        workingEnvironment: workingEnvironment,
        industry: industry,
        industrySector: (industrySector === otherTxt) ? `OTH~${industrySecTxt}` : industrySector,
      }
    };

    const newContextData = { ...globalState?.leaderData, ...updatedDataForContext }
    const redirectUrl = '/register-personal'
    updateDemographicData({ payload, leaderProfileId: props.leaderProfileId, redirectUrl, setErrorMsg, newContextData, dispatch, navigate, setShowLoader });
  };

  const backtoRolePage = () => {
    navigate('/register-role');
  }

  const chkForError = (condition: any) => {
    if (condition) {
      return 'error';
    } else {
      return 'validating';
    }
  }

  return (
    <div className="accountWrapper">
      <h1>Company</h1>
      <h2>About your current or most recent company</h2>

      {props?.orgTypeOptions?.length > 0 &&
        <>
          <div className="fieldWrap">
            <Label htmlFor="industry" size={LabelSize.Medium} text="What industry do you work in?" />
            <Select
              id="industry"
              data-test-id="industry"
              onOptionsChange={(value: any) => changeIndustry(value)}
              size={SelectSize.Large}
              classNames="selectField"
              options={props?.industryOptions}
              defaultValue={props?.industry}
              status={chkForError(errors?.industryMsg)}
            />
            {errors?.industryMsg && <span className="errorMessage">{errors?.industryMsg}</span>}
          </div>

          {industrySectOptions?.length > 0 &&
            <div className="fieldWrap">
              <Label
                htmlFor="industrySector"
                size={LabelSize.Medium}
                text="What industry sector do you work in?"
              />
              <Select
                id='industrySector'
                data-test-id="industrySector"
                onOptionsChange={(value: any) => changeIndustrySector(value)}
                size={SelectSize.Large}
                classNames="selectField"
                options={industrySectOptions !== undefined ? industrySectOptions : []}
                defaultValue={industrySector ?? "Select"}
                status={chkForError(errors?.industrySectorMsg)}
                disabled={disableIndustrySec ? true : false}
              />
              {errors?.industrySectorMsg && <span className="errorMessage">{errors?.industrySectorMsg}</span>}
            </div>
          }

          {describeIndustrySec &&
            <div className="fieldWrap">
              <TextInput
                htmlType="text"
                id="industrySecDescribe"
                clearable
                maxlength={100}
                minlength={0}
                name="industrySecDescribe"
                onChange={(e) => {
                  setindustrySecTxt(e.target.value);
                }}
                value={industrySecTxt}
                defaultValue={industrySecTxt}
                inputWidth={TextInputWidth.fill}
                classNames="inputTextField"
                size={TextInputSize.Large}
                status={errors?.industrySecDesMsg ? 'error' : 'validating'}
              />
              {errors?.industrySecDesMsg && (
                <span className="errorMessage">{errors?.industrySecDesMsg}</span>
              )}
            </div>
          }

          <div className="fieldWrap">
            <Label htmlFor="orgType" size={LabelSize.Medium} text="What type of organization do you work for?" />
            <Select
              id="orgType"
              defaultValue={props?.orgType}
              onOptionsChange={(e: any) => {
                setOrgType(e?.[0]);
              }}
              options={props?.orgTypeOptions}
              classNames="selectField"
              size={SelectSize.Large}
              status={chkForError(errors?.orgTypeMsg)}
            />
            {errors?.orgTypeMsg && <span className="errorMessage">{errors?.orgTypeMsg}</span>}
          </div>

          <div className="fieldWrap">
            <Label
              htmlFor="orgStructure"
              size={LabelSize.Medium}
              text="What is the organizational structure of your company?"
            />
            <Select
              id="orgStructure"
              defaultValue={props?.orgStructure}
              onOptionsChange={(e: any) => {
                setOrgStructure(e?.[0]);
              }}
              options={props?.orgStructureOptions}
              classNames="selectField"
              size={SelectSize.Large}
              status={chkForError(errors?.orgStructureMsg)}
            />
            {errors?.orgStructureMsg && <span className="errorMessage">{errors?.orgStructureMsg}</span>}
          </div>

          <div className="fieldWrap">
            <Label htmlFor="orgSize" size={LabelSize.Medium} text="What is the size of your organization?" />
            <Select
              id="orgSize"
              defaultValue={props?.orgSize}
              onOptionsChange={(e: any) => {
                setOrgSize(e?.[0]);
              }}
              options={props?.orgSizeOptions}
              classNames="selectField"
              size={SelectSize.Large}
              status={chkForError(errors?.orgSizeMsg)}
            />
            {errors?.orgSizeMsg && <span className="errorMessage">{errors?.orgSizeMsg}</span>}
          </div>

          <div className="fieldWrap">
            <Label
              htmlFor="orgCycle"
              size={LabelSize.Medium}
              text="What cycle/stage is your organization currently in?"
            />
            <Select
              id='orgCycle'
              defaultValue={props?.orgCycle}
              onOptionsChange={(e: any) => {
                setOrgCycle(e?.[0]);
              }}
              options={props?.orgCycleOptions}
              classNames="selectField"
              size={SelectSize.Large}
              status={chkForError(errors?.orgCycleMsg)}
            />
            {errors?.orgCycleMsg && <span className="errorMessage">{errors?.orgCycleMsg}</span>}
          </div>

          <div className="fieldWrap">
            <Label htmlFor="orgRevenue" size={LabelSize.Medium} text="How much revenue does your company generate annually?" />
            <Select
              id='orgRevenue'
              defaultValue={props?.orgRevenue}
              onOptionsChange={(e: any) => {
                setOrgRevenue(e?.[0]);
              }}
              options={props?.orgRevenueOptions}
              classNames="selectField"
              size={SelectSize.Large}
              status={chkForError(errors?.orgRevenueMsg)}
            />
            {errors?.orgRevenueMsg && <span className="errorMessage">{errors?.orgRevenueMsg}</span>}
          </div>

          <div className="fieldWrap">
            <Label htmlFor="workingEnvironment" size={LabelSize.Medium} text="What type of environment do you work in?" />
            <Select
              id="workingEnvironment"
              defaultValue={props?.workingEnvironment}
              onOptionsChange={(e: any) => {
                setWorkingEnvironment(e?.[0]);
              }}
              options={props?.workingEnvironmentOptions}
              classNames="selectField"
              size={SelectSize.Large}
              status={chkForError(errors?.workingEnvironmentMsg)}
            />
            {errors?.workingEnvironmentMsg && <span className="errorMessage">{errors?.workingEnvironmentMsg}</span>}
          </div>

          <div className="fieldWrap-Btn">
            <Icon
              classNames="secondaryButton"
              color="#BA3A19"
              data-test-id="myIconTestId"
              id="myIconId"
              path={IconName.mdiChevronLeft}
              role="presentation"
              rotate={0}
              size="20px"
              title="My icon title."
            />
            <Button
              text="Back"
              variant={ButtonVariant.SystemUI}
              classNames="backButton"
              // style={secondaryButtonStyle}
              onClick={() => backtoRolePage()}
              transparent={true}
            />
            <Button
              text="Continue"
              variant={ButtonVariant.Secondary}
              classNames="secondaryButton"
              style={secondaryButtonStyle}
              onClick={() => {
                saveCompanyData();
              }}
              iconProps={(showLoader) ? { path: IconName.mdiLoading, spin: true } : undefined}
              alignIcon={ButtonIconAlign.Right}
              disabled={showLoader}
            />
          </div>
        </>
      }
      {errorMsg && <span className="apiErrorMessage">An error occurred</span>}

    </div>
  );
};

export default Company;