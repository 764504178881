import React, { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Modal, ModalSize } from '@eightfold.ai/octuple';

function IdleTimer() {

  const [showModal, setShowModal] = useState(false);
  const timeOut: any = process.env.REACT_APP_SESSION_TIME_OUT_MILLIS;
  const logoutUser = () => {
    sessionStorage.clear();
    window.location.reload();
  };

  const onIdle = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    logoutUser();
    setShowModal(false);
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useIdleTimer({
    onIdle,
    timeout: parseInt(timeOut),
    promptTimeout: 0,
    events: ['mousemove', 'keydown', 'wheel', 'DOMMouseScroll', 'mousewheel', 'mousedown', 'touchstart', 'touchmove', 'MSPointerDown', 'MSPointerMove', 'visibilitychange'],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: true,
    name: 'idle-timer',
    syncTimers: 0,
    leaderElection: false,
  });

  return (
    <Modal
      visible={showModal}
      actionsClassNames="my-modal-actions-class"
      body="Your session has expired due to inactivity. Please log in again."
      bodyClassNames="my-modal-body-class"
      bodyPadding
      configContextProps={{
        noGradientContext: false,
        noThemeContext: false
      }}
      data-test-id="my-modal-test-id"
      header="Session Expired!"
      headerClassNames="my-modal-header-class"
      modalClassNames="my-modal-class"
      modalWrapperClassNames="my-modal-wrapper-class"
      onClose={closeModal}
      overlay
      positionStrategy="absolute"
      renderContentAlways
      size={ModalSize.small}
      themeContainerId="my-modal-theme-container"
      zIndex={1000}
    />
  );
}

export default IdleTimer;
