import React from "react";

const Header = (props: any) => {
  return (
    <div className='headerMain'>
     {props?.headerLogo  &&  <div className='headerLogo'>
        <div>
          <img alt="header image" src={props.headerLogo} />
        </div>
        <div className='dividerHeader'></div>
      </div>
      }
      <div>
        <h4 className='headerTitle'>{props.title}</h4>
       
      </div>
      <div className='authDividerHeader'></div>
      <h4 className='headerTitle'>Leader Portal</h4>
    </div>
  );
};

export default Header;
