import React, { useEffect, useState, useRef, useContext } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Outlet } from 'react-router-dom';
import Loader from '../commons/Loader';
import Api from '../Api';
import { globalContext } from "../store";

export interface UserRoles {
  name: string;
  addedTs: string;
  lastUpdatedTs: string;
}

export interface IMeData {
  id: string;
  addedTs: string;
  lastUpdatedTs: string;
  firstName: string;
  lastName: string;
  userName: string;
  roles: UserRoles[];
  //privacyPolicies: IPrivacyPolicy[];
  //applications: IMeApplications;
  clientId?: string;
}

const fetchData = async (subDomain:any,oktaAuth:any,authState:any,apiCalled:any,setToggle:any,dispatch:any) => {
  if ((authState === undefined || !authState || !authState.isAuthenticated) && !sessionStorage.getItem('loginToken')) {
    const originalUri = toRelativeUrl(window.location.href, window.location.origin);
    oktaAuth.setOriginalUri(originalUri);
    oktaAuth.signInWithRedirect();
    return <Loader />
  }
  sessionStorage.setItem('loginToken', JSON.stringify(authState));
  if (authState !== null) {
    apiCalled.current = true;
    Api.getClientByDomain(subDomain)
      .then((res) => {
        if (res?.success) {
          dispatch({
            type: "SET_CLIENT_DATA",
            payload: res?.response?.data?.data?.[0],
          });
          sessionStorage.setItem('clientId', JSON.stringify(res?.response?.data?.data[0]?.id));
          setToggle(true);
        }
      });
  }
};

export const RequiredAuth = () => {
  const { dispatch } = useContext(globalContext);

  const { oktaAuth, authState } = useOktaAuth();
  const [toggle, setToggle] = useState<boolean>(false)
  const apiCalled = useRef(false);
  const subDomain = window.location.host === 'localhost:3000' ? 'validation.test-leaders.heidricknavigator.com' : window.location.host

  useEffect(() => {
    if (apiCalled.current) return;  
    fetchData(subDomain,oktaAuth,authState,apiCalled,setToggle,dispatch);
  }, [authState]);

  return toggle ? <Outlet /> : <Loader />;
};
