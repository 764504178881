import React from 'react';
import './App.css';
import '@eightfold.ai/octuple/lib/octuple.css';
import { ConfigProvider } from '@eightfold.ai/octuple';
import AppRouter from './routes/AppRouter';

function App() {

  return (
    <ConfigProvider
      disabled={false}
      themeOptions={{
        name: "custom",
        customTheme: {
          primaryColor: "#BA3A19",
          accentColor: "#BA3A19",
        }
      }}
    >
      <AppRouter />
    </ConfigProvider>
  );
}

export default App;
